exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-atrakcje-js": () => import("./../../../src/pages/atrakcje.js" /* webpackChunkName: "component---src-pages-atrakcje-js" */),
  "component---src-pages-cennik-js": () => import("./../../../src/pages/cennik.js" /* webpackChunkName: "component---src-pages-cennik-js" */),
  "component---src-pages-galeria-js": () => import("./../../../src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-praca-js": () => import("./../../../src/pages/praca.js" /* webpackChunkName: "component---src-pages-praca-js" */),
  "component---src-pages-wspolpraca-js": () => import("./../../../src/pages/wspolpraca.js" /* webpackChunkName: "component---src-pages-wspolpraca-js" */),
  "component---src-pages-wycieczki-js": () => import("./../../../src/pages/wycieczki.js" /* webpackChunkName: "component---src-pages-wycieczki-js" */),
  "component---src-templates-attraction-js": () => import("./../../../src/templates/attraction.js" /* webpackChunkName: "component---src-templates-attraction-js" */),
  "component---src-templates-cooperation-js": () => import("./../../../src/templates/cooperation.js" /* webpackChunkName: "component---src-templates-cooperation-js" */)
}

